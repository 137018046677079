import 'jquery';

$(function() {

  /**
   * Refresh CSRF tokens for (cached) Freeform forms.
   *
   * @link https://docs.solspace.com/craft/freeform/v3/templating/caching.html#twig-template-caching
   * @link https://craftcms.com/guides/enabling-csrf-protection
   */

  var form = document.querySelector('form[data-freeform]');

  if (form) {

    /**
     * Query the form handle. If it does not exist, stop executing the function.
     */

    var handle = form.querySelector('input[name="handle"]');
    if (!handle) { return; }

    $.ajax({
      url: '/csrf?form='+handle.value,
      type: 'get',
      dataType: 'json',
      success: function(response) {
        var csrf = response.csrf;
        form.querySelector('input[name=formHash]').value = response.hash;
        form.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
      }
    });
  }
});
